/**********************************************************************/
/* ----        IMPORTANT! Read before making any changes         ---- */
/**********************************************************************/
/* ----           This file is part of a set of files            ---- */
/* ----            Any changes here MUST be added to:            ---- */
/**********************************************************************/
/* - o365.pwa.declaration.shared.dexie.databases.O365PWACore.d.ts     */
/* - o365.pwa.modules.client.dexie.databases.O365PWACore.ts           */
/* - o365.pwa.modules.sw.dexie.databases.O365PWACore.ts               */
/**********************************************************************/

import type { AppState } from 'o365.pwa.types.ts';
import type { IUserDeviceOptions } from 'o365.pwa.declaration.sw.dexie.objectStores.UserDevice.ts';
import type { ServiceWorkerStateOptions } from 'o365.pwa.declaration.sw.dexie.objectStores.ServiceWorkerState.ts';
import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { IServiceWorkerImportMapEntry } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';

// Type aliases for imported scripts
import type { App } from 'o365.pwa.declaration.shared.dexie.objectStores.App.d.ts';
import type { Database } from 'o365.pwa.declaration.shared.dexie.objectStores.Database.d.ts';
import type { ObjectStore } from 'o365.pwa.declaration.shared.dexie.objectStores.ObjectStore.d.ts';
import type { PWAState } from 'o365.pwa.declaration.shared.dexie.objectStores.PWAState.d.ts';
import type { ServiceWorkerState } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts';
import type { ServiceWorkerScriptState } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerScriptState.d.ts';
import type { AppResourceState } from 'o365.pwa.declaration.shared.dexie.objectStores.AppResourceState.d.ts';
import type { Index } from 'o365.pwa.declaration.shared.dexie.objectStores.Index.d.ts';
import type { User } from 'o365.pwa.declaration.shared.dexie.objectStores.User.d.ts';
import type { GlobalSetting } from 'o365.pwa.declaration.shared.dexie.objectStores.GlobalSetting.d.ts';
import type { UserDevice } from 'o365.pwa.declaration.shared.dexie.objectStores.UserDevice.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

declare module "o365.pwa.declaration.shared.dexie.databases.O365PWACore.d.ts" {
    export class O365PWACore extends self.Dexie.latestVersion.Dexie {
        constructor();

        /* ---- apps ---- */
        getApps(): Promise<Array<App>>;
        getApp(appId: string): Promise<App | null>;
        createApp(appId: string, title?: string, icon?: string, entrypoint?: string): Promise<void>;
        updateApp(app: App): Promise<void>;
        deleteApp(app: App): Promise<void>;
        
        /* ---- databases ---- */
        getDatabases(appId: string): Promise<Array<Database>>;
        getDatabase(appId: string, databaseId: string): Promise<Database | null>;
        createDatabase(appId: string, databaseId: string): Promise<void>;
        updateDatabase(database: Database): Promise<void>;
        deleteDatabase(database: Database): Promise<void>;
        
        /* ---- objectStores ---- */
        getObjectStores(appId: string, databaseId: string): Promise<Array<ObjectStore>>;
        getObjectStore(appId: string, databaseId: string, objectStoreId: string): Promise<ObjectStore | null>;
        createObjectStore(appId: string, databaseId: string, objectStoreId: string, jsonDataVersion: number | null): Promise<void>;
        updateObjectStore(objectStore: ObjectStore): Promise<void>;
        deleteObjectStore(objectStore: ObjectStore): Promise<void>;
        
        /* ---- indexes ---- */
        getIndexes(appId: string, databaseId: string, objectStoreId: string): Promise<Array<Index>>;
        getIndex(appId: string, databaseId: string, objectStoreId: string, indexId: string): Promise<Index | null>;
        createIndex(appId: string, databaseId: string, objectStoreId: string, indexId: string, keyPath: string | string[] | null, isPrimaryKey?: boolean, isUnique?: boolean, isMultiEntry?: boolean, isAutoIncrement?: boolean): Promise<void>;
        updateIndex(index: Index): Promise<void>;
        deleteIndex(index: Index): Promise<void>;
        
        /* ---- users ---- */
        getUser(): Promise<User | null>;
        createUser(personId: number, userSession: any): Promise<void>;
        updateUser(user: User): Promise<void>;
        deleteUser(user: User): Promise<void>;
        
        /* ---- globalSettings ---- */
        getGlobalSetting(): Promise<GlobalSetting | null>;
        createGlobalSetting(cdnUrl: any): Promise<void>;
        updateGlobalSetting(globalSetting: GlobalSetting): Promise<void>;
        deleteGlobalSetting(globalSetting: GlobalSetting): Promise<void>;
        
        /* ---- pwaStates ---- */
        getPwaState(appId: string): Promise<PWAState | null>;
        createPwaState(appId: string, appState: AppState, hasDatabaseConnection: boolean): Promise<void>;
        updatePwaState(pwaState: PWAState): Promise<void>;
        deletePwaState(pwaState: PWAState): Promise<void>;
        
        /* ---- serviceWorkerStates ---- */
        getServiceWorkerState(appId: string): Promise<ServiceWorkerState | null>;
        createServiceWorkerState(options: ServiceWorkerStateOptions): Promise<void>;
        updateServiceWorkerState(serviceWorkerState: ServiceWorkerState): Promise<void>;
        deleteServiceWorkerState(serviceWorkerState: ServiceWorkerState): Promise<void>;
        
        /* ---- serviceWorkerScriptStates ---- */
        getServiceWorkerScriptStates(appId: string): Promise<Array<ServiceWorkerScriptState>>;
        getServiceWorkerScriptState(appId: string, serviceWorkerScriptStateId: string): Promise<ServiceWorkerScriptState | null>;
        createServiceWorkerScriptState(appId: string, id: string, importmapEntry: IServiceWorkerImportMapEntry): Promise<void>;
        updateServiceWorkerScriptState(serviceWorkerScriptState: ServiceWorkerScriptState): Promise<void>;
        deleteServiceWorkerScriptState(serviceWorkerScriptState: ServiceWorkerScriptState): Promise<void>;
        
        /* ---- appResourceStates ---- */
        getAppResourceStates(appId?: string): Promise<Array<AppResourceState>>;
        getAppResourceState(appId: string, appResourceStateId: string): Promise<AppResourceState | null>;
        createAppResourceState(appId: string, id: string, relativeRoots: string[], urls: string[], scopes: string[]): Promise<void>;
        updateAppResourceState(appResourceState: AppResourceState): Promise<void>;
        deleteAppResourceState(appResourceState: AppResourceState): Promise<void>;
        
        /* ---- userDevice ---- */
        getUserDevice(): Promise<UserDevice | null>;
        createUserDevice(userDeviceOptions: IUserDeviceOptions): Promise<void>;
        updateUserDevice(userDevice: UserDevice): Promise<void>;
        deleteUserDevice(userDevice: UserDevice): Promise<void>;
    }

    export const o365PWACore: {
        readonly instance: O365PWACore;
    };
}
